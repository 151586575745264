import { InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useState } from 'react';
import './App.css';
import copilot from './copilot.png';
import logo from './logo.svg';

function App() {
  useMsalAuthentication(InteractionType.Redirect);
  const [m_strUser, setm_strUser] = useState<string>("");
  const style = {
    backgroundImage : `url(${copilot})`
  }

  function Render() {

    const { accounts} = useMsal();

    try {
      const username = accounts[0].name ?? accounts[0].username;
      setm_strUser(username);
    }
    catch (e) {
    }
  }

  if (m_strUser !== "")
    return (
      <div className="App" style={style}>
        <div className="App-header">      
          <div className="App-header-logo">
            <img src={logo} alt="logo" />            
          </div>
          <div className="App-header-text">Copilot for Finance</div>
        </div>
        <div className="App-body">
        {/* <img src={logo} className="App-logo" alt="logo" />
            <p>Welcome {m_strUser}. </p>
            <p>This page is coming soon!</p> */}
        </div>
      </div>
    );
  else
    return <>{Render()}
      <div className="App" style={style}>
        <div className="App-header">      
          <div className="App-header-logo">
            <img src={logo} alt="logo" />            
          </div>
          <div className="App-header-text">Copilot for Finance</div>
        </div>
        <div className="App-body">
            <img src={logo} className="App-logo" alt="logo" />            
            <p>Authenticating user...!</p>
        </div>
      </div>
    </>
}

export default App;
